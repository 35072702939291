@import 'variables';

body {
    background: $white;
    margin: 0px;
    padding: 0px;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
}

button {
    outline: 0;
}

.site {
    background: #ffffff;
}

.btnLogin {

    &.current_page_item {
        a {
            background: white;
            color: $green !important;

        }
    }

    a {
        padding: 6.5px 37.5px;
        border-radius: 8px;
        border: 2px solid #FFFFFF;
    }

    &:after {
        content: unset !important;
    }
}


#loginform {
    display: block;
    position: relative;
    width: 500px;
    margin: auto;
    padding: 50px 40px;
    border-radius: 10px;
    background: $lightgreen;
    margin-top: 30px;
    margin-bottom: 30px;

    .login-username,
    .login-password {
        label {
            width: 100%;
            font-size: 16px;
            color: $darkgreen;
            font-weight: bold;
        }

        input {
            width: calc(100% - 60px);
            border: 2px solid #377B20;
            border-radius: 8px;
            box-shadow: unset;
            outline: 0;
            padding: 10px 30px;
            font-weight: bold !important;
            font-size: 16px !important;
            color: $green;
            outline: 0;
            background: white;
        }
    }

    .login-submit {
        margin-bottom: 0 !important;
        text-align: center;

        input {
            cursor: pointer;
            border: 2px solid $green;
            border-radius: 8px;
            position: relative;
            background: $green;
            box-shadow: unset;
            outline: 0;
            padding: 10px 30px;
            font-weight: bold !important;
            font-size: 16px !important;
            color: white;

            &:hover {
                background: white;
                color: $green;
            }

            &:active {
                opacity: 0.5;
            }
        }
    }
}
.errorterror {
    text-align: center;
    color: rgb(255, 0, 0);
}

.goback-text {
    text-align: center;
    display: block;
    position: relative;
    padding: 10px;
    margin-bottom: 60px;
}

.btnReadon {
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    background: $darkgreen !important;
    padding: 13px 60px !important;
    text-transform: uppercase !important;
    border-radius: 8px !important;
    font-style: normal !important;
    text-decoration: none !important;
    cursor: pointer;

    i {
        margin-left: 7px;
    }

    &:after {
    }

    &:hover {
        border: 2px solid $darkgreen !important;
        background: #ffffff !important;
        color: $darkgreen !important;
    }
}

.rm_link_styles {
    text-decoration: none;
}

.vc_btn3-container,
.wpb_content_element, ul.wpb_thumbnails-fluid > li, .wpb_button {
    margin-bottom: 0px !important;
}

.loading-cat {
    width: 100%;
    padding-bottom: 75%;
    background-size: contain;
    background-repeat: no-repeat;
    display: none;
    margin-bottom: 60px;
}

.site-header {
    width: 100%;
    position: relative;

    .header-inside {
        width: 100%;
        position: fixed;
        height: $headerHeight;
        background: $green;
        z-index: 50;
        top: 0px;

        .site-logo {
            width: 450px;
            height: 159px;
            background: yellow;
            float: left;
            background: url('./assets/img/vectors/avn_logo_desktop.svg');
            background-size: contain;
            background-position: top left;
            background-repeat: no-repeat;
            transition: all 0.1s ease-in-out;
        }
        
        .burger-menu {
            display: none;
        }

        .main-navigation {
            float: right;
            margin-right: 80px;

            ul {
                margin: 0px;

                li {
                    float: left;
                    list-style: none;
                    margin: 23px 19.6px;

                    a {
                        color: #FFFFFF;
                        text-decoration: none;
                        font-style: normal;
                        font-weight: bold;

                        &:hover {
                            color: $darkgreen;
                        }
                    }

                    &:last-of-type {
                        margin-right: 0px;

                        a:hover {
                            background-color: white;
                            color: $green;
                        }
                    }

                    &:first-of-type {
                        margin-left: 0px;
                    }
                }

                .current-menu-item {
                    position: relative;
                    a {
                        color: $darkgreen;
                    }

                    &:after {
                        background: $darkgreen;
                        content: '';
                        position: absolute;
                        bottom: -3px;
                        height: 2px;
                        width: 100%;
                        left: 0px;
                        right: 0px;
                    }
                }
            }
        }
        &.scrolled {

            .site-logo {
                height: 75px;
                width: 212px;
            }
        }
    }

    .header-image-container {
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        width: 100%;
        margin-top: $headerHeight;
        margin-right: 0px;

        &.header-image-container-low {
         height: 24.30555557vw;
        }

        &.header-image-container-high {
         height: 48.42561404vw;   
            img {
                  animation: move 40s ease;
                  /* Add infinite to loop. */
                  
                  -ms-animation: move 40s ease;
                  -webkit-animation: move 40s ease;
                  -moz-animation: move 40s ease;
                  position: absolute;
                
            }
        }

        img {
            width: 100%;
        }
    }

    .breadcrumbContainer {
        background: $lightgreen;
        height: 45px;

        &.space-top {
            margin-top: $headerHeight;
        }

        .breadcrumbs {
            margin: 0px;
            padding: 0px 80px;
            line-height: 45px;

            li {
                float: left;
                list-style: none;
                line-height: 45px;

                a {
                    font-style: normal;
                    text-decoration: none;
                    font-size: 12px;
                    text-transform: uppercase;
                }

                &:after {
                    content: " > ";
                    font-size: 12px;
                    color: $green;
                    margin: 0px 5px;
                }

                &:last-of-type {
                    &:after {
                        content: unset;
                    }
                }
            }
        }
    }
}

.site-footer {
    width: 100%;
    height: $footerHeight;
    background: $lightgreen;

    .footer-inside {
        width: calc(100% - 160px);
        height: 98px;
        padding: 36px 80px 0px 80px;
        position: relative;

        .footer-menu {

            .triple-container {

                p {
                    margin: 0px;
                    font-size: 16px;
                    color: $darkgreen;

                    span {
                        font-weight: bold;
                    }

                    a {
                        font-style: normal;
                        text-decoration: none;
                        margin: 0px 6px;

                        &:first-of-type {
                            margin-left: 12px;
                        }
                    }

                    .triple-a-sm {
                        font-weight: bold;
                        font-size: 16px;
                    }

                    .triple-a-md {
                        font-weight: bold;
                        font-size: 20px;
                    }

                    .triple-a-lg {
                        font-weight: bold;
                        font-size: 25px;
                    }
                }
            }

            .main-footer {
                margin-top: 32px;

                .menu {
                    margin: 0px;
                    padding: 0px;

                    li {
                        float: left;
                        list-style: none;
                        margin: 0px 5px;

                        &:first-of-type {
                            margin-left: 0px;
                        }

                        &:last-of-type {
                            margin-right: 0px;
                        }

                        a {
                            font-style: normal;
                            text-decoration: none;
                            font-size: 16px;
                            font-weight: bold;
                        }
                    }

                    .seperator {
                        margin: 0px;
                    }

                    .btnFB,
                    .btnYT {
                        a {
                            font-weight: normal;
                        }
                    }
                }
            }
        }

        .footer-partner {

            .footer-headline {
                position: absolute;
                top: 0px;
                left: 0px;
                z-index: 1;

                p {
                    margin: 0px;
                    font-size: 16px;
                    color: $darkgreen;
                    padding-left: 10px;
                }
            }
            
            .wpb_single_image {
                float: left;
                width: 12.5%;
                position: relative;
                height: 85px;

                .wpb_wrapper,
                .vc_single_image-wrapper {
                    width: 100%;
                }

                img {
                    max-height: 85px;
                    position: absolute;
                    bottom: 0px;
                    //width: calc(100% - 10px);
                    width: 118px;
                    padding-right: 10px;
                }
            }
        }
    }
}

.site-content {
    width: 100%;
    min-height: calc(100vh - 288px);

    .content-inside {
        padding: 0px 80px;
        width: calc(100% - 160px);
        overflow: hidden;
    }

    ul {
        li {
            font-family: 'PT Sans', sans-serif;
            font-size: 18px;
            color: #000000;
            line-height: 29px;
        }
    }
}

.expandable-row {
    margin: auto;
    height: auto;
    margin-bottom: 60px;
}   

.contentheader {
    width: 100%;
    margin-bottom: 30px;

    .ch-inside {
        width: 66.66666667%;
        margin: auto;
        text-align: center;

        .ch-top {
            background: url('./assets/img/vectors/fluegel.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            position: relative;
            margin-top: 50px;
            margin-bottom: 40px;
            padding: 20px 0px;

            .ch-title, .ch-title p {
                font-family: 'dry_brushregular';
                color: $darkgreen;
                font-size: 64px;
                line-height: 72px;
                margin-top: 0;
                margin-bottom: 0px !important;
                font-weight: normal;
            }

            .ch-top-underline {
                background: url('./assets/img/vectors/swoosh.svg');
                background-repeat: no-repeat;
                background-size: contain;
                width: 220px;
                height: 25px;
                margin: auto;
                left: 50%;
                transform: translateX(-50%);
                right: auto;
                position: absolute;
                bottom: 0px;
                top: auto;
                background-position-x: center;
                background-position-y: bottom;
                background-position: center bottom;
            }
        }

        .ch-middle-large,
        .ch-middle {
            margin-top: 20px;
        }

        .ch-middle-large {
            p {
                font-size: 25px;
                font-style: italic;
                text-align: left;
            }
        }

        .ch-bottom {
            margin: 10px 0px;
            margin-top: 40px;
            padding-bottom: 30px;

            .vc_btn3 {

            }
        }

        .withoutstyle {
            p {
                font-style: italic;
                font-size: 25px;
                text-align: left;
                line-height: 35px;
                margin-bottom: 0px !important;
            }
        }
    }    
}



.home_news_card_Container {
    padding-bottom: 64px;
    margin-bottom: 60px;

    .home_news_card {
        position: relative;

        .img {
            width: 100%;
            padding-bottom: 65.53398058%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            position: relative;
        }

        .text_container {
            position: absolute;
            bottom: -64px;
            left: 0px;
            right: 0px;
            margin: auto;
            width: 292px;
            height: 104px;
            border-radius: 8px;
            background: #ffffff;
            text-align: center;


            transition: all 0.2s ease-in-out;   


                text-decoration: none;
                font-style: normal;
                text-transform: uppercase;

            h3 {
                margin: 0px;
                margin-top: 16px;
                margin-bottom: 5px;
                line-height: 30px;
                text-transform: none;
            }

            span {
                font-size: 16px;
                color: $green;
                font-weight: bold;
            }
        }

        &:hover .text_container {
            bottom: -49px;
        }
    }
}

.home_bg_with_container {
    display: table;
    width: 100%;
    background-color: $white;
    margin-bottom: 60px;

    .vc_column-inner {
        padding: 0px;
    }


    .home_bg_with_text {
        width: 50%;
        padding: 0px 88px !important;
        display: table-cell;
        vertical-align: middle;
        float: none;
        text-align: center;

        .vc_btn3 {
            margin-top: 32px;
        }

        h3 {
            margin-top: 0px;
        }

        p {
            margin-top: 25px;
            margin-bottom: 42px;
        }
    }

    .home_bg_with_image {
        width: 100%;
        font-size: 0px;

        .img {
            min-height: 500px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
}

.magazin_container {
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
    margin-bottom: 60px;

    .vc_btn3 {
        margin-top: 0;
        border:2px solid $green;
        cursor:pointer;
    }

    a {
        text-decoration: none !important;
    }

    .container-inside {
        text-align: left;

        .magazin_card {
            width: 100%;
            float: left;
            margin-bottom: 47px;

            .card-inside {
       

                .card_header {
                    width: 100%;
                    padding-bottom: 65.53398058%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    position: relative;
                    background-color: $white;
                }

                .card_body {
                    a {
                        font-style: normal;
                        color: #000000;
                        font-size: 16px;
                    }

                    .categories {
                        margin-top: 15px;

                        a, span {
                            text-decoration: underline !important;
                        }
                    }

                    h3 {
                        text-decoration: none;
                        margin-top: 7px;
                        margin-bottom: 0px;
                    }

                    p {
                        margin: 0px;
                        margin-top: 15px;
                        &:last-of-type {
                            margin-bottom: 0 !important;
                        }
                    }
                }
            }
        }
    }
}

.partner_container {
    width: 100%;
    margin-bottom: 13px;

    .container-inside {

        .partner_card {
            width: 100%;
            float: left;
            margin-bottom: 47px;

            .card-inside {

                .card_header {
                    width: 100%;
                    padding-bottom: 65.53398058%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    position: relative;
                    background-color: $white;
                }

                .card_body {
                    margin-top: 25px;
                    
                    a {

                    }

                    h3 {
                    }

                    p {
                        margin: 0px;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}

.magazin_manager_container {
    width: 100%;
    margin-bottom: 13px;

    .container-inside {

        .partner_card {
            width: 100%;
            float: left;
            margin-bottom: 47px;

            .card-inside {

                .card_header {
                    width: 100%;
                    padding-bottom: 65.53398058%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    position: relative;
                    background-color: $white;
                }

                .card_body {
                    margin-top: 25px;

                    a {
                        font-style: normal;
                        color: #000000;
                        font-size: 16px;
                    }

                    .categories {
                        margin-top: 15px;
                    }

                    h3 {
                    }

                    p {
                        margin: 0px;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}

._highlight_Container {
    background: $white;
    width: 100%;
    display: table;
    margin-bottom: 60px;

    .text-inside {
        padding: 0px 85px;
        width: 50%;
        display: table-cell;
        vertical-align: middle;
        float: none;

        a {
            font-style: normal;
            color: #000000;
            font-size: 16px;
        }
        .categories {
            margin-bottom: 5px;

            span {
                font-style: normal;
                color: #000000;
                font-size: 16px;
                text-decoration: underline;
            }
        }

        .headline {

        }

        p {
            margin: 0px;
            margin-top: 25px;
            margin-bottom: 0px !important;
        }
    }

    .highlight-img {
        width: 100%;
        padding-bottom: 62.5%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
        background-color: $white;
    }
}


.videoplayer-container {
    position: relative;
    padding-bottom: 36.5625%;
    height: 0;
    width: calc(100% - 20px);
    margin: auto;
    margin-bottom: 60px;


    .videoplayer-posterimage {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center center;
        .videoplayer-playbutton {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 150px;
            width: 150px;
            background-image: url("assets/img/play_button.png");
            background-size: cover; 
            transform: translate(-50%,-50%);
            cursor:pointer;  
            opacity: 0.75; 
            border-radius: 100%;
        }

        .videoplayer-youtubelogo {
                position: absolute;
                right: 0px;
                bottom: 0px;
                height: 41px;
                width: 85px;
                background-image: url(assets/img/yt_logo_mono_dark.svg);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                transform: translate(-50%, -50%);
                opacity: 1;
        }
    }

    .youtube-player-player {
        position: absolute;
        height: 100%;
        width: 100%;
        display: none;
        top: 0;
        left: 0;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

    }
}


.downloadmanager_Container_all {
    padding-top: 30px;
    margin-bottom: 60px;

    ._inside {

        .download {
            padding-bottom: 11px;

            i {
                color: $darkgreen;
                font-size: 45px;
                vertical-align: middle;
                padding-right: 10px;
            }

            a {
                font-size: 16px;
                font-style: normal;
                font-weight: bold;
                vertical-align: middle;
            }


        }
    }

    .vc_row.wpb_row.vc_inner.vc_row-fluid:last-of-type {
        .download {
            padding-bottom: 0px;
        }
    }
}

.downloadmanager_Container_single {
    margin: 20px 0px;

    ._inside {
        i {
            color: $darkgreen;
            font-size: 45px;
            vertical-align: middle;
            padding-right: 10px;
        }

        a {
            font-size: 18px;
            font-style: normal;
            font-weight: bold;
        }
    }
}

.article_Image_Container {
    margin-bottom: 45px;

    .image-inside {

        .img {
            width: 100%;
            padding-bottom: 65.53398058%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            position: relative;
            background-color: $lightgreen;

            i {
                position: absolute;
                right: 10px;
                bottom: 10px;
                background: #ffffff;
                padding: 6px;
                border-radius: 8px;
                border: 2px solid $darkgreen;
            }
        }

        p {
            margin: 0px;
            font-size: 14px;
            line-height: 20px;
        }
    }
}

.readon_Container {
    margin: auto;
    padding-bottom: 70px;

    .wpb_text_column {

    }

    .readon_resttext_Container {
        position: relative;
        background: #ffffff;
        overflow: hidden;
        height: auto;

        ._readon-inside {
            height: auto;
            overflow: hidden;
            text-align: center;

            .full-text {
                text-align: left;
                display: none;
                height: auto;
            }

            .btnReadon {
                border: 2px solid $darkgreen !important;

                &:focus {
                    outline: none;
                }
            }
        }
    }
}

.glossary_container {
    position: relative;

    .glossary_filters {
        margin-bottom: 50px;

        .select-schutzgueter {

            .selectize-input {
                border: 2px solid $darkgreen;
                border-radius: 8px;
                background: #ffffff;
                position: relative;
                padding-top: 11px;
                padding-bottom: 11px;
                box-shadow: unset;

                .item,
                input {
                    color: $darkgreen !important;
                    font-weight: bold !important;
                    font-size: 16px !important;

                    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: $darkgreen;
                    }

                    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                        color: $darkgreen;
                    }

                    &::-ms-input-placeholder { /* Microsoft Edge */
                        color: $darkgreen;
                    }

                    color: $darkgreen;

                    .item {
                        color: $darkgreen;
                    }
                }

                &:after {
                    background-color: $darkgreen;
                    position: absolute;
                    right: 0px;
                    width: 45px;
                    height: 100%;
                    top: 0px;
                    bottom: 0px;
                    margin: auto;
                    padding: unset;
                    border: 0px;
                    content: "\f107";
                    font-family: Fontawesome;
                    color: #ffffff;
                    font-size: 35px;
                    text-align: center;
                    line-height: 40px;
                }
            }

            .selectize-input.dropdown-active {
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 0px;
            }
            
            .selectize-dropdown {
                background-color: #ffffff;
                color: $darkgreen;
                border: 2px solid $darkgreen;
                border-top-width: 0px;
                border-radius: 0px;
                box-shadow: unset;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;

                .option {
                    min-height: 35px;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 35px;
                }

                .active {
                    background-color: $darkgreen;
                    color: #ffffff;
                }
            }
            
            &:hover {
                &.selectize-control:before {
                    width: 2px;
                    height:45px;
                    content:"";
                    position:absolute;
                    background-color: white;
                    top: 0px;
                   
                    right: 47px;
                    z-index: 2;
                    
                }

                &> .selectize-input {
                    background: $darkgreen;
                    color: #ffffff;

                    .item,
                    input {

                        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                            color: #ffffff;
                        }

                        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                            color: #ffffff;
                        }

                        &::-ms-input-placeholder { /* Microsoft Edge */
                            color: #ffffff;
                        }

                        color: #ffffff !important;
                    }

                    &:before {
                        display: none;
                    }
                }
            }
        } 
    }

    .glossary_content {
        min-height: 150px;

        .glossary_card {
            position: relative;
            margin-bottom: 60px;

            .card-header {
                width: 100%;
                background-color: #F6F8F3;

                .img {
                    width: 100%;
                    padding-bottom: 65.53398058%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    position: relative;
                    background-color: $lightgreen;
                }

                i {
                    position: absolute;
                    right: 10px;
                    bottom: 10px;
                    background: #ffffff;
                    padding: 6px;
                    border-radius: 8px;
                    border: 2px solid $darkgreen;
                }
            }

            .card-body {

                .categories {
                    margin-top: 16px;

                    a, span {
                        font-style: normal;
                        color: #000000;
                        font-size: 16px;
                        text-decoration: underline;
                    }
                }

                .headline {
                    margin-top: 22px;

                    .latinname {
                        font-style: italic;
                        font-size: 18px;
                        font-weight: normal;
                    }

                    h4 {
                        margin: 0px;
                    }
                }

                .headline_link {
                    margin-top: 5px;
                    margin-bottom: 20px;

                    a {
                        text-decoration: none;
                        font-style: normal;

                        h3 {
                            margin-top: 0px;
                            margin-bottom: 0px;
                        }
                    }
                    
                }

                .text {

                    p {
                        margin: 0px;
                    }
                }
            }
        }
    }
}

h3.header_w_underline {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 25px;

    &:after {
        content: '';
        position: absolute;
        height: 2px;
        width: 40px;
        background: $darkgreen;
        left: 0px;
        bottom: 0px;
    }
}



.infobox_Container {
    position: relative;
    margin-bottom: 60px;
    margin-top: 10px;    
    background: $white;

    .infobox-inside {
        padding: 65px 85px 65px 85px;

        p {
            margin-bottom: 0 !important;
        }

        .header_w_underline {
            margin-top: 0;
        }
    }

    &:before {
        content: '\f129';
        background: $darkgreen;
        position: absolute;
        font-family: Fontawesome;
        top: -10px;
        left: 28px;
        height: 40px;
        width: 40px;
        color: $white;
        font-size: 35px;
        text-align: center;
        line-height: 40px;
        border-radius: 8px;
        z-index: 1;
    }

    .img_box {
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .img {
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 70.75471698%;
            height: 100%;
            margin: auto;
            background-color: #FCFCFB;
            background-color: #FFFFFF;

            i {
                position: absolute;
                right: 20px;
                bottom: 20px;
                background: #ffffff;
                padding: 6px;
                border-radius: 8px;
                border: 2px solid #377B20;
            }
        }
    }
}

.post-footer-container {

    .pfc-text {
        text-align: center;
        margin-bottom: 40px;

        h3 {
            text-align: left;
            margin-top: 60px;
            position: relative;
            padding-top: 20px;

            &:before {
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                height: 2px;
                background: $darkgreen;
            }
        }
    }
}

.post-header-container {
    margin-top: 50px;
    .categories {
        a, span {
            font-size: 16px;
            color: #000000;
            font-style: normal;
            text-decoration: underline;
        }
    }

    h1 {

    }
}

.vc_tta-container {
    border-top: 2px solid;
    border-bottom: 2px solid;
    border-color: $darkgreen;

    .vc_tta-panel {
        .vc_tta-panel-heading {
            background-color: transparent !important;
            border: 0 !important;

            h4 {
                a {
                    padding-left: 0px !important;
                    padding-right: 0px !important;

                    .vc_tta-title-text {
                        font-weight: bold;
                        font-style: normal;
                        font-size: 24px;
                        color: $darkgreen;
                    }

                    i {
                        right: 0px;
                        left: unset !important;
                        transform: unset !important;
                        font-style: normal !important;
                        top: 0px !important;
                        bottom: 0px !important;
                        height: 100% !important;
                        line-height: 0px;
                        text-align: right;
                        width: calc(100% - 12px) !important;
                        margin-right: 12px;

                        &:before {
                            content: '\f107' !important;
                            font-family: Fontawesome !important;
                            border: 0 !important;
                            transform: unset !important;
                            color: $darkgreen !important;
                            font-size: 30px;
                        }

                        &:after {
                            content: unset !important;
                        }
                    }
                }
            }
        }

        .vc_tta-panel-body {
            padding: 10px 0px !important;
            background-color: transparent !important;
            border: 0 !important;

            table {
                width: 100%;
                border-collapse: collapse;
                border-spacing: 0;

                tr {
                    height: 45px;

                    td:first-of-type {
                        padding-left: 12px;
                    }

                    td:last-of-type {
                        padding-right: 12px;
                    }
                }

                tr:nth-child(odd) {
                    background-color: $white;
                }

                tr, tr a {
                    font-size: 18px !important;
                }
            }
        }
    }

    .vc_tta-panel.vc_active {
        .vc_tta-panel-heading {
            h4 {
                a {
                    i {
                        &:before {
                            content: '\f106' !important;
                        }
                    }
                }
            }
        }
    }
}

.interactive_map_Container {
    position: relative;
    width: 100%;
    margin-bottom: 60px;
    background-color: $white;

    #saver {
        display: none;
    }

    .vc_row {
        .vc_col-sm-12 {
            &:first-of-type {
                width: 47.80297107%;
            }

            &:last-of-type {
                width: 52.19702893%;
            }
        }
    }

    .map_container {
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: contain;
        position: relative;
        margin: 0px 25px;

        .map_overlay {
            width: 100%;
            height: 100%;
            max-height: calc(100vh - 75px);

            svg {
                height: calc(100vh - 75px);
            }

            .hovered {
                fill: $darkgreen !important;
                color: $darkgreen;
                text-transform: uppercase;
            }

            .pin {
                position: absolute;
                top: 0;
                left: 0;
                background-repeat: no-repeat;
                background-position: 0 0;
                background-size: contain;
                width: 39px;
                height: 48px;

                -webkit-transition: all 500ms ease-out 0s;
                -moz-transition: all 500ms ease-out 0s;
                -o-transition: all 500ms ease-out 0s;
                transition: all 500ms ease-out 0s;
            }
        }
    }

    .schutzgebiete_container {
        height: 100%;
        width: calc(100% - 115px);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        .swiper-schutzgebiete {
            width: 100%;
            height: 100%;
            margin: auto;
            overflow: hidden;

            .swiper-wrapper {
                .swiper-slide {
                    text-align: center;
                    position: relative;
                    display: block;

                    .inside {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);

                        h2 {
                            position: relative;
                            padding-bottom: 50px;
                            margin-bottom: 50px;

                            &:after {
                                position: absolute;
                                content: '';
                                bottom: 0px;
                                left: 0px;
                                width: 100%;
                                background: $darkgreen;
                                height: 2px;
                            }
                        }

                        p {
                            margin-bottom: 50px !important;
                            margin-left: 7px;
                            margin-right: 7px;
                        }   
                    }
                }
            }

            .swiper-button-prev {
                top: 25px;
                left: 50%;
                transform: translateX(-50%);
                right: auto;
                bottom: auto;
                height: 61px;
                width: 39px;
                background-image: none;

                &:before {
                    color: $darkgreen;
                    font-family: Fontawesome;
                    content: '\f106';
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    font-size: 60px;
                }

                &:active {
                    opacity: 0.5;
                }
            }

            .swiper-button-next {
                bottom: 25px;
                top: auto;
                left: 50%;
                transform: translateX(-50%);
                right: auto;
                height: 61px;
                width: 39px;
                background-image: none;

                &:before {
                    color: $darkgreen;
                    font-family: Fontawesome;
                    content: '\f107';
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    font-size: 60px;
                }

                &:active {
                    opacity: 0.5;
                }
            }
        }
    }
}

.schutzgebiet_verlinker_container {
    margin-bottom: 60px;
    
    .verlinker_card {
        position: relative;
        display: block;
        margin-bottom: 105px;

        .img {
            width: 100%;
            padding-bottom: 65.53398058%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            position: relative;

            .text_container {
                position: absolute;
                bottom: -64px;
                left: 0px;
                right: 0px;
                margin: auto;
                width: 292px;
                height: 104px;
                border-radius: 8px;
                background: #ffffff;
                text-align: center;
                -webkit-transition: all 0.2s ease-in-out;
                transition: all 0.2s ease-in-out;
                text-decoration: none;
                font-style: normal;
                text-transform: uppercase;

                h3 {
                    margin: 0px;
                    margin-top: 16px;
                    margin-bottom: 5px;
                    line-height: 30px;
                    text-transform: none;
                }

                span {
                    font-size: 16px;
                    color: #AAC80C;
                    font-weight: bold;
                }
            }   
        }

        &:hover .text_container {
            bottom: -49px;
        }
    }

    .append_verlinker_container {
        text-align: center;

        .btnLoadOn {
            background: $darkgreen !important;
            border: 2px solid $darkgreen !important;
            cursor: pointer;

            &:hover {
                color: $darkgreen !important;
                background: #ffffff !important;
            }
        }
    }
}

.infowarning_container {
    margin-bottom: 30px;
    overflow: hidden;

    .warning_img {
        width: 60px;
        height: 60px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
        float: left;
        margin-right: 30px;
    }

    .text_container {
        float: right;
        width: calc(100% - 90px);

        p {
            margin: 0px !important;
        }

        h4 {
            line-height: 18px;
            margin: 0px;
        }

    }
}

.schutzgueter_loadon_container {

    .text_area {
        h3 {
            position: relative;
            margin-bottom: 20px;
            padding-top: 35px;

            &:before {
                content: '';
                display: block;
                position: absolute;
                background: $darkgreen;
                height: 2px;
                top: 0;
                left: 0;
                width: 100%;
            }
        }
    }


    .schutzgut_card {

        .img {
            width: 100%;
            padding-bottom: 65.53398058%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            position: relative;
            background-color: $white;
        }

        .card-body {
            padding-top: 20px;
            text-align: left;

            .headline {

                h4 {
                    margin-top: 2px;
                    margin-bottom: 0px;

                    span {
                        font-style: italic;
                        font-weight: normal;
                        font-size: 18px;
                    }
                }
            }

            .text {

            }
        }
    }

    .append_loadon_container {
        text-align: center;

        .btnLoadOn {
            background: $darkgreen !important;
            border: 2px solid $darkgreen !important;
            cursor: pointer;

            &:hover {
                color: $darkgreen !important;
                background: #ffffff !important;
            }
        }

    }
}

.slider_container {
    width: 100%;
    overflow: hidden;
    margin-bottom: 10px;

    .swiper-schutzgut {
        position: relative;

        .swiper-wrapper {

            .swiper-slide {

                .swiper_img {
                    width: 100%;
                    padding-bottom: 55.18867925%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    position: relative;
                    background-color: $lightgreen;
                }

                .caption {
                    p {
                        font-size: 14px;
                        font-weight: normal;
                        text-align: left;
                        color: #000000;
                    }
                }
            }
        }

        .swiper-button-prev {
            background-image: none;
            left: 25px !important;
            top: 50%;
            transform: translateY(-50%);

            &:after {
                content: '\f104';
                position: absolute;
                font-size: 60px;
                color: #ffffff;
                font-family: Fontawesome;
                vertical-align: middle;
                top: -25%;
            }

            &:active {
                opacity: 0.5;
            }
        }

        .swiper-button-next {
            background-image: none;
            right: 25px !important;
            top: 50%;
            transform: translateY(-50%);

            &:after {
                content: '\f105';
                position: absolute;
                font-size: 60px;
                color: #ffffff;
                font-family: Fontawesome;
                vertical-align: middle;
                top: -25%;
            }

            &:active {
                opacity: 0.5;
            }
        }
    }    
}

.iframe-container {
    width: 100%;
    margin-bottom: 60px;

    .calendarEventDate {
        float: left;
        width: 55px;
        height: 55px;
        background: url('./assets/img/icon_calendar_green.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        border-top: 2px solid #85AC3B;
        padding-top: 10px;

        .calendarEventDateMonth {
            font-size: 9px;
            font-weight: bold;
            color: white;
            margin: 3px 0 0 11px;
        }

        .calendarEventDateDay {
            font-size: 16px;
            font-weight: bold;
            color: $green;
            margin: 3px 0 0 11px;
        }
    }

    .calendarEvent {
        position: relative;
        float: left;
        width: calc(100% - 55px);
        margin-bottom: 60px;
        border-bottom: 1px solid $green;
        border-top: 2px solid #85AC3B;
        padding-top: 10px;
        height: auto;
        overflow: hidden;
        padding-bottom: 15px;

        .calendarListLink {
            text-decoration: none;
            font-style: normal;

            .calendarEventData {
                width: 80px;
                float: left;
                top: 0;
                bottom: 0;
                margin: auto;
                line-height: 15px;
                padding-left: 20px;

                span {
                    font-size: 13px;
                    color: $darkgreen;
                }
            }

            .calendarEventContent {
                width: calc(100% - 130px);
                float: left;
                top: 0;
                bottom: 0;
                left: 100px;
                right: 0;
                margin: auto;
                padding-top: 2px;
                margin-left: 30px;
                height: 100%;

                span {
                    width: 100%;
                    float: left;
                }
            }

            .calendarEventListTopic {
                font-size: 11px;
                font-weight: bold;
                color: $darkgreen;
                line-height: 15px;
            }

            .calendarEventContentTitle {
                font-size: 15px;
                color: $darkgreen;
                line-height: 20px;
                padding-top: 2px;
            }
        }

        .clear.block {
            display: none;
        }

        .calendarEventListFree {

            img {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }

    br {
        display: none;
    }
}

.wall-outer {
    margin-bottom: 60px !important;

    .dc-wall  {

        .dcwss-content {

            .stream {

                .dcsns-li {

                    .section-intro {
                        background-color: $darkgreen !important;

                        a {
                            margin-left: 5px;
                        }
                        
                    }

                    a {
                        .socicon {
                            bottom: 6px !important;
                            padding: 5px;
                            border-radius: 5px;
                            left: 5px;

                            &:hover {
                                background-color: $darkgreen !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .dcsns-toolbar {
        .option-set {
            .active {
                a {
                    background-color: $darkgreen !important;

                    &:hover {
                        background-color: $green !important;
                    }
                }
            }

            li {
                &:first-of-type {
                    a {
                        background-color: $green !important;

                        &:hover {
                            background-color: $darkgreen !important;
                        }
                    }
                }
            }
        }
    }
}

.single_img_container {
    width: 100%;

    .single-img-inside {
        .img {
            width: 100%;
            padding-bottom: 65.53398058%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            position: relative;
            background-color: #EEF4CE;
        }
    }
}



@-webkit-keyframes move {
  0% {
    -webkit-transform-origin: bottom left;
    -moz-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    -o-transform-origin: bottom left;
    transform-origin: bottom left;
    transform: scale(1.0);
    -ms-transform: scale(1.0);
    /* IE 9 */
    
    -webkit-transform: scale(1.0);
    /* Safari and Chrome */
    
    -o-transform: scale(1.0);
    /* Opera */
    
    -moz-transform: scale(1.0);
    /* Firefox */
  }
  100% {
    transform: scale(1.2);
    -ms-transform: scale(1.2);
    /* IE 9 */
    
    -webkit-transform: scale(1.2);
    /* Safari and Chrome */
    
    -o-transform: scale(1.2);
    /* Opera */
    
    -moz-transform: scale(1.2);
    /* Firefox */
  }
}

